// MUI Imports
import Chip from '@mui/material/Chip'
import { useTheme } from '@mui/material/styles'

// Third-party Imports
import PerfectScrollbar from 'react-perfect-scrollbar'

// Type Imports
import type { VerticalMenuContextProps } from '@menu/components/vertical-menu/Menu'

// Component Imports
import { Menu, SubMenu, MenuItem, MenuSection } from '@menu/vertical-menu'

// Hook Imports
import useVerticalNav from '@menu/hooks/useVerticalNav'

// Styled Component Imports
import StyledVerticalNavExpandIcon from '@menu/styles/vertical/StyledVerticalNavExpandIcon'

// Style Imports
import menuItemStyles from '@core/styles/vertical/menuItemStyles'
import menuSectionStyles from '@core/styles/vertical/menuSectionStyles'
import useMultilangHook from '@/hooks/LanguageHook/Multilanguages-hook'
import { useEffect, useState } from 'react'

type RenderExpandIconProps = {
  open?: boolean
  transitionDuration?: VerticalMenuContextProps['transitionDuration']
}

const RenderExpandIcon = ({ open, transitionDuration }: RenderExpandIconProps) => (
  <StyledVerticalNavExpandIcon open={open} transitionDuration={transitionDuration}>
    <i className='ri-arrow-right-s-line' />
  </StyledVerticalNavExpandIcon>
)

const VerticalMenu = ({ scrollMenu }: { scrollMenu: (container: any, isPerfectScrollbar: boolean) => void }) => {
  // Hooks
  const theme = useTheme()
  const { isBreakpointReached, transitionDuration } = useVerticalNav()

  const ScrollWrapper = isBreakpointReached ? 'div' : PerfectScrollbar

  const { handleLanguageChange, multiLanguagesData }: any = useMultilangHook();
  const [multiLang, setMultiLang] = useState<any>([])
  const [userData, setuserData] = useState<any>([]);

  useEffect(() => {
    if (multiLanguagesData[0]?.value != undefined) {
      console.log("multi_lang?.multi_lang", multiLanguagesData[0]?.value);
      setMultiLang(multiLanguagesData[0]?.value);
    }

  });

  useEffect(() => {


    if (typeof window !== 'undefined') {
      if (localStorage.getItem('userProfileData') != 'undefined') {
        // alert(localStorage.getItem('userData'))
        setuserData(JSON.parse(localStorage.getItem('userProfileData') || '[]'))
      }
    }
  }, []);
  return (
    // eslint-disable-next-line lines-around-comment
    /* Custom scrollbar instead of browser scroll, remove if you want browser scroll only */
    <ScrollWrapper
      {...(isBreakpointReached
        ? {
          className: 'bs-full overflow-y-auto overflow-x-hidden',
          onScroll: container => scrollMenu(container, false)
        }
        : {
          options: { wheelPropagation: false, suppressScrollX: true },
          onScrollY: container => scrollMenu(container, true)
        })}
    >
      {/* Incase you also want to scroll NavHeader to scroll with Vertical Menu, remove NavHeader from above and paste it below this comment */}
      {/* Vertical Menu */}
      <Menu
        menuItemStyles={menuItemStyles(theme)}
        renderExpandIcon={({ open }) => <RenderExpandIcon open={open} transitionDuration={transitionDuration} />}
        renderExpandedMenuItemIcon={{ icon: <i className='ri-circle-line' /> }}
        menuSectionStyles={menuSectionStyles(theme)}
      >
        {/* <SubMenu
          label='Dashboards'
          icon={<i className='ri-home-smile-line' />}
          suffix={<Chip label='5' size='small' color='error' />}
        >
          <MenuItem
            href={`${process.env.NEXT_PUBLIC_PRO_URL}/dashboards/crm`}
            suffix={<Chip label='Pro' size='small' color='primary' variant='tonal' />}
            target='_blank'
          >
            CRM
          </MenuItem>
          <MenuItem href='/'>Analytics</MenuItem>
          <MenuItem
            href={`${process.env.NEXT_PUBLIC_PRO_URL}/dashboards/ecommerce`}
            suffix={<Chip label='Pro' size='small' color='primary' variant='tonal' />}
            target='_blank'
          >
            eCommerce
          </MenuItem>
          <MenuItem
            href={`${process.env.NEXT_PUBLIC_PRO_URL}/dashboards/academy`}
            suffix={<Chip label='Pro' size='small' color='primary' variant='tonal' />}
            target='_blank'
          >
            Academy
          </MenuItem>
          <MenuItem
            href={`${process.env.NEXT_PUBLIC_PRO_URL}/dashboards/logistics`}
            suffix={<Chip label='Pro' size='small' color='primary' variant='tonal' />}
            target='_blank'
          >
            Logistics
          </MenuItem>
        </SubMenu>
        <SubMenu
          label='Front Pages'
          icon={<i className='ri-file-copy-line' />}
          suffix={<Chip label='Pro' size='small' color='primary' variant='tonal' />}
        >
          <MenuItem href={`${process.env.NEXT_PUBLIC_PRO_URL}/front-pages/landing-page`} target='_blank'>
            Landing
          </MenuItem>
          <MenuItem href={`${process.env.NEXT_PUBLIC_PRO_URL}/front-pages/pricing`} target='_blank'>
            Pricing
          </MenuItem>
          <MenuItem href={`${process.env.NEXT_PUBLIC_PRO_URL}/front-pages/payment`} target='_blank'>
            Payment
          </MenuItem>
          <MenuItem href={`${process.env.NEXT_PUBLIC_PRO_URL}/front-pages/checkout`} target='_blank'>
            Checkout
          </MenuItem>
          <MenuItem href={`${process.env.NEXT_PUBLIC_PRO_URL}/front-pages/help-center`} target='_blank'>
            Help Center
          </MenuItem>
        </SubMenu> */}

        <MenuItem
          href={`/`}
          icon={<i className='ri-home-smile-line' />}
        // suffix={<Chip label='Pro' size='small' color='primary' variant='tonal' />}
        // target='_blank'
        >
          {(multiLang?.menu_dashboard) ? multiLang?.menu_dashboard : "Dashboard"}
        </MenuItem>
        <MenuItem
          href={`/installation`}
          icon={<i className='ri-install-line' />}
        // suffix={<Chip label='Pro' size='small' color='primary' variant='tonal' />}
        // target='_blank'
        >

          {(multiLang?.menu_installation) ? multiLang?.menu_installation : "Installation"}
        </MenuItem>
        {/* <SubMenu label='Jobs' icon={<i className='ri-team-line' />}>
              <MenuItem href='#' >
                {(multiLang?.menu_job_requests) ? multiLang?.menu_job_requests : "Requests"}
              </MenuItem>
              <MenuItem href='#' >
                {(multiLang?.menu_work_orders) ? multiLang?.menu_work_orders : "Work orders"}
              </MenuItem>
              <MenuItem href='#' >
                {(multiLang?.menu_service_appnt) ? multiLang?.menu_service_appnt : "Service appointments"}
              </MenuItem>
              <MenuItem
                href={`/installation`}
                icon={<i className='ri-install-line' />}
              // suffix={<Chip label='Pro' size='small' color='primary' variant='tonal' />}
              // target='_blank'
              >

                {(multiLang?.menu_installation) ? multiLang?.menu_installation : "Installation"}
              </MenuItem>
            </SubMenu>
            <SubMenu label='Customers' icon={<i className='ri-contacts-book-line' />}>
              <MenuItem href='#' >
                {(multiLang?.menu_contacts) ? multiLang?.menu_contacts : "Contacts"}
              </MenuItem>
              <MenuItem href='#' >
                {(multiLang?.menu_companies) ? multiLang?.menu_companies : "Companies"}
              </MenuItem>
            </SubMenu>
            <MenuItem
          href={`#`}
          icon={<i className='ri-install-line' />}
        >

          {(multiLang?.menu_finance) ? multiLang?.menu_finance : "Finance"}
        </MenuItem> */}
        
        <MenuItem
          href={`/survey`}
          icon={<i className='ri-calendar-line' />}
        // suffix={<Chip label='Pro' size='small' color='primary' variant='tonal' />}
        // target='_blank'
        >

          {(multiLang?.menu_survey) ? multiLang?.menu_survey : "Survey"}
        </MenuItem>
        <MenuItem
          href={`/rewards`}
          icon={<i className='ri-gift-line' />}
        // suffix={<Chip label='Pro' size='small' color='primary' variant='tonal' />}
        // target='_blank'
        >

          {(multiLang?.menu_rewards) ? multiLang?.menu_rewards : "Rewards"}
        </MenuItem>
        <MenuItem href='/my-account' icon={<i className='ri-user-settings-line' />}>

          {(multiLang?.menu_my_account) ? multiLang?.menu_my_account : "My Account"}
        </MenuItem>
        {/* <MenuItem href='/my-company' icon={<i className='ri-building-office-line' />}>

          {(multiLang?.menu_my_company) ? multiLang?.menu_my_company : "My Company"}
        </MenuItem> */}
        {/* <SubMenu label='Auth Pages' icon={<i className='ri-shield-keyhole-line' />}>
            <MenuItem href='/login' target='_blank'>
              Login
            </MenuItem>
            <MenuItem href='/register' target='_blank'>
              Register
            </MenuItem>
            <MenuItem href='/forgot-password' target='_blank'>
              Forgot Password
            </MenuItem>
          </SubMenu>
          <SubMenu label='Miscellaneous' icon={<i className='ri-question-line' />}>
            <MenuItem href='/error' target='_blank'>
              Error
            </MenuItem>
            <MenuItem href='/under-maintenance' target='_blank'>
              Under Maintenance
            </MenuItem>
          </SubMenu>
          <MenuItem href='/card-basic' icon={<i className='ri-bar-chart-box-line' />}>
            Cards
          </MenuItem>*/}
        {(userData?.userType == "Administrator" || userData?.userType == "Nuetech Admin") && (
          <MenuSection label='ADMIN OPTIONS'>
            <SubMenu label='User' icon={<i className='ri-shield-keyhole-line' />}>
              {/* <MenuItem href='#' >
                {(multiLang?.menu_field_agents) ? multiLang?.menu_field_agents : "Field agents"}
              </MenuItem> */}
              <MenuItem href='/users/sales-partner' >
              {(multiLang?.menu_sales_partner) ? multiLang?.menu_sales_partner : "Sales Partner"}
              </MenuItem>
              {/* <MenuItem href='#' >
                {(multiLang?.menu_employees) ? multiLang?.menu_employees : "Employees"}
              </MenuItem> */}
            </SubMenu>

          
           
            {/* <MenuItem href='#' icon={<i className='ri-building-4-fill' />}>

            {(multiLang?.menu_inventory) ? multiLang?.menu_inventory : "Inventory"}
            </MenuItem>
            <MenuItem href='#' icon={<i className='ri-file-chart-line' />}>
            {(multiLang?.menu_reports) ? multiLang?.menu_reports : "Reports"}
            </MenuItem>
            <SubMenu label='Settings' icon={<i className='ri-settings-2-line' />}>
              <MenuItem href='#'>
                {(multiLang?.menu_my_company) ? multiLang?.menu_my_company : "Company Settings"}
              </MenuItem>
            </SubMenu>
            <MenuItem href='#' icon={<i className='ri-file-copy-2-line' />}>
            {(multiLang?.menu_integrations) ? multiLang?.menu_integrations : "Integrations"}
            </MenuItem> */}

          </MenuSection>

        )}
      </Menu>
    </ScrollWrapper>
  )
}

export default VerticalMenu
